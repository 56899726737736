import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const Services = () => (
  <Layout hideHeaderFooter={true}>
    <SEO title="Services" />
    <h1>Services</h1>
    <p>Welcome to page 2</p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
);

export default Services;
